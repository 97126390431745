import { Document, DocumentType, IncomingInvoice } from '../../../types/document/documentTypes'
import CellButtonsDocuments from '../../_atoms/CellButtonsDocuments/CellButtonsDocuments'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'

import styles from './styles.module.scss'

interface IProps {
    document: Document | IncomingInvoice
    isSafari: boolean
    type: DocumentType
}

const CellActionsDocuments = ({ document, isSafari, type }: IProps) => {
    const overlayHeightInPx = () => {
        const baseHeight = 28
        const perLineHeight = 18
        if (isSafari) {
            const displayedTitle = document.title
            const additionalLinesCount = Math.ceil(displayedTitle.length / 27)
            return baseHeight + perLineHeight * additionalLinesCount
        } else {
            return 0
        }
    }
    return (
        <div className={styles.wrap}>
            {document.isDeleted ? (
                <OverlayWithText
                    text={'УДАЛЕНО'}
                    backgroundBootstrapColor={'bg-danger'}
                    textBootstrapColor={'text-light'}
                    heightInPx={overlayHeightInPx()}
                />
            ) : (
                <CellButtonsDocuments documentId={document.id} type={type} />
            )}
        </div>
    )
}

export default CellActionsDocuments
