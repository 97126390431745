import { colors, fontWeights } from '../../constants/salesStyles'
import { carFields } from '../../types/car/carTypes'
import { fieldTypes, valueTypes } from '../../types/common/commonTypes'
import { Marketplaces, MarketplacesKeys, partFields } from '../../types/part/partTypes'

export const initialPartState = {
    fields: [
        {
            field: partFields.useExistingCar,
            fieldType: fieldTypes.switch,
            value: 0,
            valueType: valueTypes.number,
            title: 'Автомобиль',
            specialProps: {
                switchRenderOptions: ['НЕТ', 'Выбрать', 'Добавить'],
                column: 1,
                customWidth: 158,
            },
        },
        {
            field: partFields.carId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            title: '',
            specialProps: {
                isVisibleSwitch: [{ field: partFields.useExistingCar, value: 1 }],
                isRequired: true,
                renderValue: '',
                placeholder: 'выберите автомобиль',
                column: 1,
                isFullWidth: true,
            },
        },
        {
            field: carFields.markId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            title: 'Марка',
            specialProps: {
                isVisibleSwitch: [{ field: partFields.useExistingCar, value: 2 }],
                isRequired: true,
                placeholder: 'выберите марку',
                column: 1,
                renderValue: '',
            },
        },
        {
            field: carFields.modelId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            title: 'Модель',
            specialProps: {
                isVisibleSwitch: [{ field: partFields.useExistingCar, value: 2 }],
                isRequired: true,
                placeholder: 'выберите модель',
                fieldParent: carFields.markId,
                column: 1,
            },
        },
        {
            field: carFields.modificationId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            title: 'Модификация',
            specialProps: {
                isVisibleSwitch: [{ field: partFields.useExistingCar, value: 2 }],
                isRequired: true,
                isSelectSearch: true,
                placeholder: 'выберите модификацию',
                fieldParent: carFields.modelId,
                column: 1,
            },
        },
        {
            field: carFields.bodyTypeId,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'Тип кузова',
            specialProps: {
                isVisibleSwitch: [{ field: partFields.useExistingCar, value: 2 }],
                placeholder: 'определяется автоматически',
                fieldParent: carFields.modificationId,
                column: 1,
            },
        },
        {
            field: carFields.year,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            title: 'Год',
            specialProps: {
                isVisibleSwitch: [{ field: partFields.useExistingCar, value: 2 }],
                // isRequired: true,
                placeholder: 'выберите год',
                fieldParent: carFields.modificationId,
                column: 1,
            },
        },
        {
            field: partFields.groupId,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'Категория',
            specialProps: {
                placeholder: 'определяется автоматически',
                column: 1,
            },
        },
        {
            field: partFields.typeId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            /*
                13000 - колёса в сборе
                13003 - запасное колесо

                13001 - шины
                13004 - шина

                13002 - диски
                13005 - диск

                9072 - аккумулятор

                13013 - Колпаки колесные

                255 - Антифриз - охлаждающие жидкости
                256 - Тормозная жидкость - тормозные жидкости
                257 - Жидкость ГУР - гидравлические жидкости
                258 - Масло двухтактное - моторные масла
                260 - Моторное масло - моторные масла
            */
            value: 0,
            title: 'Название',
            specialProps: {
                isRequired: true,
                placeholder: 'название запчасти',
                column: 1,
            },
        },
        {
            field: partFields.Quantity,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Количество',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                isRequired: true,
                hoverText: 'Количество шт. в комплекте. От 1 до 8',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.TireType,
            fieldType: fieldTypes.select,
            valueType: valueTypes.string,
            value: '',
            title: 'Сезонность',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                hoverText: 'Сезонность: Всесезонные, Летние, Зимние нешипованные, Зимние шипованные',
                isRequired: true,
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimType,
            fieldType: fieldTypes.select,
            valueType: valueTypes.string,
            value: '',
            title: 'Тип диска',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                hoverText: 'Тип диска: Кованые, Литые, Штампованные, Спицованные, Сборные',
                isRequired: true,
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.Brand,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Бренд',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                isRequired: true,
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.TireModel,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Модель',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                fieldParent: partFields.Brand,
                isRequired: true,
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimDiameter,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Диаметр',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                    { field: partFields.typeId, value: 13013 },
                ],
                isRequired: true,
                hoverText: 'Диаметр, дюймы',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.TireSectionWidth,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Ширина профиля',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                isRequired: true,
                hoverText: 'Ширина профиля шины',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.TireAspectRatio,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Высота профиля',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13001 },
                    { field: partFields.typeId, value: 13004 },
                ],
                isRequired: true,
                hoverText: 'Высота профиля шины',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimWidth,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Ширина обода',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                isRequired: true,
                hoverText: 'Ширина обода, дюймы',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimOffset,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Вылет (ET)',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                isRequired: true,
                hoverText: 'Вылет (ET)',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimBolts,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Болты',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                isRequired: true,
                hoverText: 'Количество отверстий под болты',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimBoltsDiameter,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Диаметр под болты',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                isRequired: true,
                hoverText: 'Диаметр расположения отверстий под болты',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.RimDIA,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Диаметр ЦО',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 13000 },
                    { field: partFields.typeId, value: 13003 },
                    { field: partFields.typeId, value: 13002 },
                    { field: partFields.typeId, value: 13005 },
                ],
                isRequired: true,
                hoverText: 'Диаметр центрального отверстия',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.OilBrand,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Производитель',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 255 },
                    { field: partFields.typeId, value: 256 },
                    { field: partFields.typeId, value: 257 },
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.SAE,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Класс вязкости',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                hoverText: 'Класс вязкости SAE',
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.Volume,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Объём',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 255 },
                    { field: partFields.typeId, value: 256 },
                    { field: partFields.typeId, value: 257 },
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.ACEA,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Стандарт ACEA',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.API,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Стандарт API',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.OEMOil,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Допуски OEM',
            specialProps: {
                isVisibleAny: [
                    { field: partFields.typeId, value: 255 },
                    { field: partFields.typeId, value: 256 },
                    { field: partFields.typeId, value: 257 },
                    { field: partFields.typeId, value: 258 },
                    { field: partFields.typeId, value: 260 },
                ],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.Color,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Цвет',
            specialProps: {
                isVisibleAny: [{ field: partFields.typeId, value: 255 }],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.ASTM,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Стандарт ASTM',
            specialProps: {
                isVisibleAny: [{ field: partFields.typeId, value: 255 }],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.DOT,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Стандарт DOT',
            specialProps: {
                isVisibleAny: [{ field: partFields.typeId, value: 256 }],
                fieldParent: partFields.typeId,
                isRequired: true,
                column: 1,
                extraHandle: 'oils',
            },
        },
        {
            field: partFields.customTitle,
            fieldType: fieldTypes.textarea,
            valueType: valueTypes.string,
            value: '',
            title: 'Название публикации',
            specialProps: {
                placeholder: 'название для маркетплейсов',
                textareaMaxLength: 50,
                hoverText: `если поле останется пустым, название для публикации сформируется автоматически в формате: "запчасть" + "автомобиль"`,
                isTouched: false,
                column: 1,
                isClearButton: true,
            },
        },
        {
            field: partFields.conditionId,
            fieldType: fieldTypes.select,
            valueType: valueTypes.number,
            title: 'Состояние',
            specialProps: {
                isRequired: true,
                placeholder: 'выберите состояние',
                column: 1,
            },
        },
        // {
        //     field: partFields.PartBrand,
        //     fieldType: fieldTypes.selectSearch,
        //     valueType: valueTypes.string,
        //     value: '',
        //     title: 'Бренд запчасти',
        //     specialProps: {
        //         isVisibleAny: [{ field: partFields.conditionId, value: 1 }],
        //         isRequired: true,
        //         column: 1,
        //         extraHandle: 'wheels',
        //     },
        // },
        {
            field: partFields.ResidualTread,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Глубина протектора',
            specialProps: {
                isVisibleEvery: [
                    [
                        { field: partFields.conditionId, value: 2 },
                        { field: partFields.conditionId, value: 3 },
                        { field: partFields.conditionId, value: 4 },
                    ],
                    [
                        { field: partFields.typeId, value: 13000 },
                        { field: partFields.typeId, value: 13003 },
                        { field: partFields.typeId, value: 13001 },
                        { field: partFields.typeId, value: 13004 },
                    ],
                ],
                isRequired: true,
                hoverText:
                    'Остаточная глубина протектора шины. Может принимать значения от 1 до 50 включительно, измеряется в миллиметрах (мм) В диапазоне 1-10 мм включительно можно использовать дробные значения.',
                column: 1,
                extraHandle: 'wheels',
            },
        },
        {
            field: partFields.position,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.string,
            title: 'Расположение',
            specialProps: {
                placeholder: 'определяется автоматически',
                column: 1,
            },
        },
        {
            field: partFields.partNumber,
            fieldType: fieldTypes.partNumber,
            valueType: valueTypes.string,
            value: '',
            title: 'Основной OEM номер',
            specialProps: {
                placeholder: 'введите OEM номер',
                column: 1,
                maxAddedFields: 14,
                alignItemsStretch: true,
            },
        },
        {
            field: partFields.noValue,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.string,
            title: 'Описание',
            specialProps: {
                placeholder: '',
                column: 1,
            },
        },
        {
            field: partFields.description,
            fieldType: fieldTypes.textarea,
            valueType: valueTypes.string,
            value: '',
            title: '',
            specialProps: {
                placeholder: 'введите описание запчасти',
                textareaMinHeight: 400,
                textareaMaxLength: 7500,
                column: 1,
                isFullWidth: true,
            },
        },
        {
            field: partFields.partPricePurchase,
            fieldType: fieldTypes.input,
            value: 0,
            valueType: valueTypes.price,
            title: 'Цена закупки',
            specialProps: {
                isRequired: false,
                placeholder: 'введите цену',
                column: 2,
            },
        },
        {
            field: partFields.partPrice,
            fieldType: fieldTypes.input,
            value: 0,
            valueType: valueTypes.price,
            title: 'Цена продажи',
            specialProps: {
                isRequired: false,
                placeholder: 'введите цену',
                column: 2,
                alignItemsStretch: true,
            },
        },
        {
            field: partFields.partImages,
            fieldType: fieldTypes.files,
            value: [],
            valueType: valueTypes.files,
            title: '',
            specialProps: {
                renderValue: 'Добавить фотографии',
                column: 2,
                isFullWidth: true,
                extraHandle: 'partImages',
                borderTop: false,
            },
        },
        {
            field: partFields.warehouseTitle,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.string,
            value: '',
            title: 'Склад',
            specialProps: {
                column: 2,
            },
        },
        {
            field: partFields.warehouseRow,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Номер ряда',
            specialProps: {
                placeholder: 'введите номер ряда',
                column: 2,
            },
        },
        {
            field: partFields.warehouseShelf,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Номер полки',
            specialProps: {
                placeholder: 'введите номер полки',
                column: 2,
            },
        },
        {
            field: partFields.warehousePlace,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Номер места',
            specialProps: {
                placeholder: 'введите номер места',
                column: 2,
            },
        },
        {
            field: partFields.vendorCode,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Артикул',
            specialProps: {
                placeholder: 'введите артикул',
                column: 2,
                maxLength: 20,
                copyButton: true,
                printerButton: true,
            },
        },
        {
            field: partFields.VideoUrl,
            fieldType: fieldTypes.input,
            valueType: valueTypes.string,
            value: '',
            title: 'Ссылка на видео',
            specialProps: {
                placeholder: 'введите ссылку на видео',
                column: 2,
            },
        },
        {
            field: partFields.IncomingInvoice,
            fieldType: fieldTypes.select,
            valueType: valueTypes.string,
            value: '',
            title: 'Приходная накладная',
            specialProps: {
                placeholder: 'не выбрано',
                column: 2,
            },
        },
        {
            field: partFields.marketplacesDest,
            fieldType: fieldTypes.checkboxGroup,
            valueType: valueTypes.array,
            value: Object.keys(Marketplaces).map(key => ({
                field: key as MarketplacesKeys,
                value: true,
            })),
            title: 'Разместить на маркетплейсах',
            specialProps: {
                column: 2,
                extraHandle: 'marketplacesDest',
                checkboxItems: Object.entries(Marketplaces).map(([key, title]) => ({
                    field: key as MarketplacesKeys,
                    fieldType: fieldTypes.checkbox,
                    valueType: valueTypes.boolean,
                    value: true,
                    title,
                    specialProps: {
                        column: 2,
                        extraHandle: 'marketplacesDest',
                    },
                })),
            },
        },
        {
            field: partFields.applicability,
            fieldType: fieldTypes.applicability,
            valueType: valueTypes.boolean,
            value: false,
            title: 'Применимость',
            specialProps: {
                column: 2,
                isTitleFullWidth: true,
                isShowAccordionButton: true,
            },
        },
        {
            field: carFields.applicabilityMarkId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Марка',
            specialProps: {
                isVisibleEvery: [
                    [{ field: partFields.applicability, value: true }],
                    [{ field: partFields.applicabilityButtons, value: true }],
                ],
                isRequired: true,
                placeholder: 'выберите марку',
                column: 2,
                renderValue: '',
                addedField: partFields.applicability,
                borderColor: colors.grayLighter,
            },
        },
        {
            field: carFields.applicabilityModelId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Модель',
            specialProps: {
                isVisibleEvery: [
                    [{ field: partFields.applicability, value: true }],
                    [{ field: partFields.applicabilityButtons, value: true }],
                ],
                isRequired: true,
                placeholder: 'выберите модель',
                fieldParent: carFields.applicabilityMarkId,
                column: 2,
                addedField: partFields.applicability,
                borderColor: colors.grayLighter,
            },
        },
        {
            field: carFields.applicabilityModificationId,
            fieldType: fieldTypes.selectSearch,
            valueType: valueTypes.number,
            value: 0,
            title: 'Модификация',
            specialProps: {
                isVisibleEvery: [
                    [{ field: partFields.applicability, value: true }],
                    [{ field: partFields.applicabilityButtons, value: true }],
                ],
                isRequired: true,
                placeholder: 'выберите модификацию',
                fieldParent: carFields.applicabilityModelId,
                column: 2,
                addedField: partFields.applicability,
                borderColor: colors.grayLighter,
            },
        },
        {
            field: carFields.applicabilityBodyTypeId,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.number,
            title: 'Тип кузова',
            specialProps: {
                isVisibleEvery: [
                    [{ field: partFields.applicability, value: true }],
                    [{ field: partFields.applicabilityButtons, value: true }],
                ],
                placeholder: 'определяется автоматически',
                fieldParent: carFields.applicabilityModificationId,
                column: 2,
                addedField: partFields.applicability,
                borderColor: colors.grayLighter,
            },
        },
        {
            field: carFields.applicabilityYear,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.string,
            title: 'Год',
            specialProps: {
                isVisibleEvery: [
                    [{ field: partFields.applicability, value: true }],
                    [{ field: partFields.applicabilityButtons, value: true }],
                ],
                placeholder: 'определяется автоматически',
                fieldParent: carFields.applicabilityModificationId,
                column: 2,
                addedField: partFields.applicability,
                borderColor: colors.grayLighter,
            },
        },
        {
            field: partFields.applicabilityButtons,
            fieldType: fieldTypes.applicabilityButtons,
            valueType: valueTypes.boolean,
            value: false,
            title: '',
            specialProps: {
                isVisibleEvery: [
                    [{ field: partFields.applicability, value: true }],
                    [{ field: partFields.applicabilityButtons, value: true }],
                ],
                column: 2,
                borderColor: colors.grayLighter,
            },
        },
        {
            field: partFields.noValue,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.string,
            title: 'Рекомендованные',
            specialProps: {
                isVisibleEvery: [
                    [
                        {
                            field: partFields.applicabilitySuggestion,
                            value: true,
                        },
                    ],
                    [{ field: partFields.applicability, value: true }],
                ],
                placeholder: '',
                column: 2,
                isTitleFullWidth: true,
                rowHeight: 40,
                borderColor: colors.grayLighter,
                fontWeight: fontWeights.normal,
            },
        },
        {
            field: partFields.applicabilitySuggestion,
            fieldType: fieldTypes.applicabilitySuggestion,
            valueType: valueTypes.boolean,
            value: false,
            title: '',
            specialProps: {
                isVisibleEvery: [
                    [
                        {
                            field: partFields.applicabilitySuggestion,
                            value: true,
                        },
                    ],
                    [{ field: partFields.applicability, value: true }],
                ],
                borderTop: false,
                column: 2,
                isFullWidth: true,
                applicabilitySuggestionItems: [],
                rowHeight: 50,
            },
        },
        {
            field: partFields.noValue,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.string,
            title: 'Добавленные',
            specialProps: {
                isVisibleEvery: [
                    [{ field: partFields.applicabilitySelected, value: true }],
                    [{ field: partFields.applicability, value: true }],
                ],
                placeholder: '',
                column: 2,
                isTitleFullWidth: true,
                rowHeight: 40,
                borderColor: colors.grayLighter,
                fontWeight: fontWeights.normal,
            },
        },
        {
            field: partFields.applicabilitySelected,
            fieldType: fieldTypes.applicabilitySelected,
            valueType: valueTypes.boolean,
            value: false,
            title: '',
            specialProps: {
                isVisibleEvery: [
                    [{ field: partFields.applicabilitySelected, value: true }],
                    [{ field: partFields.applicability, value: true }],
                ],
                borderTop: false,
                column: 2,
                isFullWidth: true,
                applicabilityItems: [],
                rowHeight: 50,
            },
        },
        {
            field: partFields.dimensionsAndWeight,
            fieldType: fieldTypes.readOnly,
            valueType: valueTypes.boolean,
            title: 'Габариты и вес с упаковкой',
            value: false,
            specialProps: {
                placeholder: '',
                column: 2,
                isTitleFullWidth: true,
                isShowAccordionButton: true,
            },
        },
        {
            field: partFields.height,
            fieldType: fieldTypes.input,
            value: '',
            valueType: valueTypes.string,
            title: 'Высота, см',
            specialProps: {
                isVisibleSwitch: [{ field: partFields.dimensionsAndWeight, value: true }],
                column: 2,
                borderTop: false,
            },
        },
        {
            field: partFields.width,
            fieldType: fieldTypes.input,
            value: '',
            valueType: valueTypes.string,
            title: 'Ширина, см',
            specialProps: {
                isVisibleSwitch: [{ field: partFields.dimensionsAndWeight, value: true }],
                column: 2,
                borderTop: false,
            },
        },
        {
            field: partFields.length,
            fieldType: fieldTypes.input,
            value: '',
            valueType: valueTypes.string,
            title: 'Длина, см',
            specialProps: {
                isVisibleSwitch: [{ field: partFields.dimensionsAndWeight, value: true }],
                column: 2,
                borderTop: false,
            },
        },
        {
            field: partFields.weight,
            fieldType: fieldTypes.input,
            value: '',
            valueType: valueTypes.string,
            title: 'Вес, кг',
            specialProps: {
                isVisibleSwitch: [{ field: partFields.dimensionsAndWeight, value: true }],
                column: 2,
                borderTop: false,
            },
        },
    ],
}
