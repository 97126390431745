import { useState } from 'react'

import avatarPlaceholder from '../../../assets/icons/imagePlaceholder.svg'
import useHoverHandler from '../../../hooks/sales/useHoverHandler'
import { useGetSmsPhoneNumber } from '../../../hooks/user/useAuth'
import { useLogout } from '../../../hooks/user/useLogout'
import { adminRouteAlias, getAdminNavigationPath } from '../../../router/adminRouteAlias'
import CustomLink from '../../_atoms/CustomLink/CustomLink'
import ModalChangeCurrency from '../ModalChangeCurrency/ModalChangeCurrency'

import styles from './styles.module.scss'

const HeaderProfile = () => {
    const [isHover, hoverOn, hoverOff] = useHoverHandler()
    const [isModalChangeCurrencyOpen, setIsModalChangeCurrencyOpen] = useState(false)
    const phoneNumber = useGetSmsPhoneNumber()
    const logout = useLogout()

    const handleModalChangeCurrencyOpen = () => {
        setIsModalChangeCurrencyOpen(true)
    }

    const authorizedLinks = [
        {
            text: `Контакты`,
            href: getAdminNavigationPath(adminRouteAlias.shop.location),
        },
        // { text: `XML Данные`, href: '/', onClick: logout, target: '_blank' },
        { text: 'Валюта', href: '#!', onClick: handleModalChangeCurrencyOpen },
        { text: `Выход`, href: '/', onClick: logout, target: '_self' },
    ]

    return (
        <div
            className={`${styles.wrap} ${isHover ? styles.wrapWithBorder : ''}`}
            onMouseEnter={hoverOn}
            onMouseLeave={hoverOff}
        >
            <div className={styles.userInfoWrap}>
                <span>{phoneNumber}</span>
                <img src={avatarPlaceholder} alt="user avatar placeholder" className={styles.avatar} />
            </div>
            {!isHover ? null : (
                <div className={styles.menuItemsWrap}>
                    {authorizedLinks.map(item => (
                        <div className={styles.menuItem} key={item.text} onClick={hoverOff}>
                            <CustomLink
                                text={item.text}
                                href={item.href}
                                onClick={item?.onClick}
                                target={item?.target}
                            />
                        </div>
                    ))}
                </div>
            )}

            {isModalChangeCurrencyOpen && (
                <ModalChangeCurrency
                    isOpen={isModalChangeCurrencyOpen}
                    closeModal={() => {
                        setIsModalChangeCurrencyOpen(false)
                    }}
                />
            )}
        </div>
    )
}

export default HeaderProfile
