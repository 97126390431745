import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { getOrderColumns } from '../../../constants/defaultCustomizableColumns'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const setUserActiveColumnsPartList =
    (activeColumns: string[]) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { userData } = getState()
        const config = {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
            },
        }
        const params = {
            userId: userData.smsAuth.userId,
            activeColumns,
        }

        dispatch({
            type: storeDispatchTypes.setCustomizableColumnsLoading,
            value: true,
        })

        try {
            const {
                data: { status, data },
            } = await apiInstance.post('/setUserActiveColumnsPartList', params, config)

            if (status === 'ok' && data) {
                dispatch({
                    type: storeDispatchTypes.setCustomizableColumns,
                    value: getOrderColumns(data.activeColumnsPartList),
                })
            }
        } catch (e) {
            console.error('get user active columns failed', e)
        } finally {
            dispatch({
                type: storeDispatchTypes.setCustomizableColumnsLoading,
                value: false,
            })
        }
    }

export default setUserActiveColumnsPartList
