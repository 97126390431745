import { TCurrencyKeys } from '../types/common/commonTypes'

export const currencySymbols: { [key in TCurrencyKeys]: string } = {
    USD: '$',
    EUR: '€',
    RUR: '₽',
    AED: 'Dh',
    KZT: '₸',
    BYN: 'Br',
}

export const getCurrencySymbol = (currency: TCurrencyKeys): string => currencySymbols[currency]
