import React from 'react'

import { colors, fontSizes, fontWeights } from '../../../constants/salesStyles'
import { useSelector } from '../../../modules/store/customSelector'
import { userSummaryFields } from '../../../types/sales/salesTypes'
import { getCurrencySymbol } from '../../../utils/getCurrencySymbol'
import SalesText from '../../_atoms/SalesText/SalesText'

interface IProps {
    field: userSummaryFields | 'publishedPartsCount'
    value: number
    isLast?: boolean
    prefix?: string
}

const SalesUserSummaryText = ({ field, value, isLast, prefix }: IProps) => {
    const currentCurrency = useSelector(state => state.partList.currentCurrency)

    const getUnitOfMeasurement = () => {
        if (
            field === 'soldCount' ||
            field === 'totalCount' ||
            field === 'publishedPartsCount' ||
            field === 'totalCountWithQty'
        ) {
            return 'шт'
        } else if (field === 'soldSum' || field === 'totalSum') {
            return getCurrencySymbol(currentCurrency)
        } else if (field === 'soldRate') {
            return '%'
        }
    }

    return (
        <SalesText
            text={`${prefix ? prefix + ' ' : ''} ${String(value.toLocaleString('ru-RU'))} ${getUnitOfMeasurement()} ${isLast ? '' : '\u00a0•\u00a0'}`}
            fontWeight={fontWeights.semiBold}
            fontSize={fontSizes.l}
            color={colors.blue}
            marginTopPixels={8}
        />
    )
}

export default SalesUserSummaryText
