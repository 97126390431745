import { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Trash3 } from 'react-bootstrap-icons'

import receipt from '../../../assets/icons/receipt.png'
import DowloadDocument from '../../../modules/redux/documentList/DowloadDocument'
import getDocumentById from '../../../modules/redux/documentList/GetDocumentById'
import setDocumentDeletedStatus from '../../../modules/redux/documentList/SetDocumentDeletedStatus'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { DocumentType } from '../../../types/document/documentTypes'
import { RegisterCheckByParts } from '../../../utils/kkmserver'
import HoverOverlay from '../HoverOverlay/HoverOverlay'
import Loader from '../Loader/Loader'

import styles from './styles.module.scss'

interface IProps {
    documentId: string
    type: DocumentType
}

const CellButtonsDocuments = ({ documentId, type }: IProps) => {
    const dispatch = useDispatch()
    const [isLoadingReceipt, setIsLoadingReceipt] = useState(false)
    const cashierInfo = useSelector(state => state.userData.cashierInfo)
    const currentCurrency = useSelector(state => state.partList.currentCurrency)
    const isLoadingCurrentCurrency = useSelector(state => state.partList.loaders.currentCurrency)
    const isLoadingDocumentDownloaded = useSelector(state => state.documentList.loaders.isDocumentDownloaded)

    const handleReceiptClick = async () => {
        setIsLoadingReceipt(true)
        const document = await dispatch(getDocumentById(documentId))
        document && RegisterCheckByParts(document.extra_data.parts, cashierInfo?.cashier)
        setIsLoadingReceipt(false)
    }
    const handleCreateXLS = () => {
        dispatch(DowloadDocument(documentId, type, 'xlsx', currentCurrency))
    }

    return (
        <>
            {cashierInfo?.connectCashbox ? (
                isLoadingReceipt ? (
                    <Loader diameterInPx={28} thicknessInPx={3} margin={0} />
                ) : (
                    <img src={receipt} alt="receipt" className={styles.icon} onClick={handleReceiptClick} />
                )
            ) : null}

            {isLoadingCurrentCurrency || isLoadingDocumentDownloaded ? (
                <Loader diameterInPx={28} thicknessInPx={3} margin={0} />
            ) : (
                <HoverOverlay tooltipText={'Скачать XLS'} preventWrapFullWidth>
                    <Button variant={'primary'} style={{ width: 70 }} size="sm" onClick={handleCreateXLS}>
                        XLS
                    </Button>
                </HoverOverlay>
            )}
            <HoverOverlay tooltipText={'Удалено'} preventWrapFullWidth>
                <Button
                    variant="danger"
                    size="sm"
                    onClick={() => dispatch(setDocumentDeletedStatus(documentId, type))}
                    className="d-flex align-items-center"
                >
                    <Trash3 color={'white'} size={18} />
                </Button>
            </HoverOverlay>
        </>
    )
}

export default CellButtonsDocuments
