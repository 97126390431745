import { ChangeEvent, useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

import setUserActiveCurrency from '../../../modules/redux/userSettings/SetUserActiveCurrency'
import { useDispatch } from '../../../modules/store/customDispatch'
import { useSelector } from '../../../modules/store/customSelector'
import { ECurrency, TCurrencyKeys } from '../../../types/common/commonTypes'
import Loader from '../../_atoms/Loader/Loader'
import OverlayWithText from '../../_atoms/OverlayWithText/OverlayWithText'

import styles from './styles.module.scss'

interface IProps {
    isOpen: boolean
    closeModal: () => void
}

const ModalChangeCurrency = ({ isOpen, closeModal }: IProps) => {
    const dispatch = useDispatch()
    const currentCurrency = useSelector(state => state.partList.currentCurrency)
    const isLoading = useSelector(state => state.partList.loaders.currentCurrency)
    const [currency, setCurrency] = useState<TCurrencyKeys>(currentCurrency)

    const onCloseModal = () => {
        closeModal()
    }

    const confirm = async () => {
        await dispatch(setUserActiveCurrency(currency))
        onCloseModal()
    }

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const newValue = e.target.value as TCurrencyKeys
        setCurrency(newValue)
    }

    useEffect(() => {
        setCurrency(currentCurrency)
    }, [currentCurrency])

    return (
        <Modal show={isOpen} onHide={onCloseModal} centered dialogClassName={styles.modal}>
            {isLoading && (
                <OverlayWithText backgroundBootstrapColor={'bg-secondary'}>
                    <Loader diameterInPx={100} thicknessInPx={10} />
                </OverlayWithText>
            )}
            <Modal.Header closeButton>
                <Modal.Title>
                    <b>Валюта</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Select value={currency} onChange={handleSelectChange}>
                    {Object.entries(ECurrency).map(([key, value]) => (
                        <option key={key} value={key}>
                            {value}
                        </option>
                    ))}
                </Form.Select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={confirm}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalChangeCurrency
