import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { TCurrencyKeys } from '../../../types/common/commonTypes'
import { apiInstance } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const setUserActiveCurrency =
    (currency: TCurrencyKeys) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { userData } = getState()
        const config = {
            headers: {
                Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
            },
        }
        const params = {
            userId: userData.smsAuth.userId,
            currency,
        }

        dispatch({
            type: storeDispatchTypes.setPartsLoader,
            value: { field: 'currentCurrency', value: true },
        })

        try {
            const {
                data: { status, data },
            } = await apiInstance.post('/setUserActiveCurrency', params, config)

            if (status === 'ok' && data?.currency) {
                dispatch({
                    type: storeDispatchTypes.setCurrentCurrency,
                    value: data.currency,
                })
            }
        } catch (e) {
            console.error('get user active currency failed', e)
        } finally {
            dispatch({
                type: storeDispatchTypes.setPartsLoader,
                value: { field: 'currentCurrency', value: false },
            })
        }
    }

export default setUserActiveCurrency
