import { useEffect } from 'react'

import getUserActiveCurrency from '../../modules/redux/userSettings/GetUserActiveCurrency'
import { useDispatch } from '../../modules/store/customDispatch'

export const useGetUserActiveCurrency = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUserActiveCurrency())
    }, [])
}
