import React, { useEffect, useState } from 'react'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons'

import moment from 'moment'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'

import Loader from '../../components/_atoms/Loader/Loader'
import SalesButton from '../../components/_atoms/SalesButton/SalesButton'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import { colors, fontSizes } from '../../constants/salesStyles'
import loadSalesChartData from '../../modules/redux/partList/LoadSalesChartData'
import { useDispatch } from '../../modules/store/customDispatch'
import { useSelector } from '../../modules/store/customSelector'
import { SoldChartData, SoldPartsByDay } from '../../types/sales/statsTypes'
import { getCurrencySymbol } from '../../utils/getCurrencySymbol'
import { sortByField } from '../../utils/sorters'

import styles from './styles.module.scss'

const CustomTooltip = ({ active, payload, currentCurrency }: any) => {
    if (active && payload && payload.length) {
        const date = payload[0]?.payload.formatDate
        return (
            <div className={styles.customTooltip}>
                <p className={styles.label}>{date}</p>
                <p
                    className={styles.label}
                >{`${payload[0].value.toLocaleString('ru-RU')} ${getCurrencySymbol(currentCurrency)}`}</p>
                <p className={styles.label}>{`${payload[1].value} запчастей`}</p>
                <p className={styles.label}>{`С/Ч ${Math.round(payload[0].value / payload[1].value).toLocaleString(
                    'ru-RU',
                )} ${getCurrencySymbol(currentCurrency)}`}</p>
            </div>
        )
    }

    return <div>no data</div>
}

const SalesChart = () => {
    const dispatchRedux = useDispatch()

    const currentCurrency = useSelector(state => state.partList.currentCurrency)
    const isLoadingCurrentCurrency = useSelector(state => state.partList.loaders.currentCurrency)

    const [chartData, setChartData] = useState(null as SoldChartData | null)
    const [isLoading, setIsLoading] = useState(true)

    const loadChartList = async () => {
        try {
            const chartData = await dispatchRedux(loadSalesChartData())
            if (chartData) {
                setChartData(chartData)
            }
            setIsLoading(false)
        } catch (e) {
            console.error('loadChartList error', e)
        }
    }

    useEffect(() => {
        if (!chartData) {
            loadChartList()
        }
    }, [chartData])

    const [selectedDate, setSelectedDate] = useState(new Date())

    const month = selectedDate.getMonth()
    const year = selectedDate.getFullYear()

    const formatDate = (day: number | string) => moment([year, month, day]).format('DD.MM.YYYY')

    const firstDay = 1
    const tenthDay = 10
    const twentiethDay = 20
    const lastDay = +moment([year, month]).endOf('month').format('DD')

    const isNow = new Date().getMonth() === month

    const monthFormatter = (date: Date) => moment(date).format('MMMM YYYY')

    const onMonthChange = (diff: number) => {
        setSelectedDate(prevMonth => new Date(prevMonth.getFullYear(), prevMonth.getMonth() + diff))
    }

    const handleReset = () => setSelectedDate(new Date())

    const extractDateComponents = function (dateStr: string): { dayNum: number; monthNum: number; fullYear: number } {
        const date = new Date(dateStr)

        return {
            dayNum: date.getDate(),
            monthNum: date.getMonth(),
            fullYear: date.getFullYear(),
        }
    }

    const ticks = [firstDay, tenthDay, twentiethDay, lastDay]

    const soldPartsByDay: SoldPartsByDay[] = (chartData?.soldPartsByDay || []).filter(soldPart => {
        if (!soldPart.date) return false
        const { monthNum, fullYear } = extractDateComponents(soldPart.date)
        return monthNum === month && fullYear === year
    })

    let items = soldPartsByDay.map(i => {
        const { dayNum } = extractDateComponents(i.date)
        i.totalPrice
        return {
            date: dayNum,
            totalPrice: +i.totalPrice,
            totalQuantity: +i.totalQuantity,
            formatDate: formatDate(dayNum),
        }
    })
    items = sortByField(items, 'date')

    return isLoading || isLoadingCurrentCurrency ? (
        <Loader />
    ) : (
        <div style={{ height: '100%', width: '100%' }}>
            <div className={styles.chartControls}>
                <SalesButton
                    borderRadius={10}
                    backgroundColor={colors.grayDark}
                    paddingHorizontalPixels={12}
                    paddingVerticalPixels={7}
                    onClick={() => onMonthChange(-1)}
                >
                    <ChevronLeft size={18} color={colors.white} />
                </SalesButton>
                <SalesButton
                    borderRadius={10}
                    backgroundColor={colors.grayDark}
                    paddingHorizontalPixels={12}
                    paddingVerticalPixels={7}
                    onClick={handleReset}
                >
                    <SalesText
                        className={styles.monthName}
                        text={monthFormatter(selectedDate)}
                        fontSize={fontSizes.s}
                        color={colors.white}
                    />
                </SalesButton>
                <SalesButton
                    borderRadius={10}
                    backgroundColor={colors.grayDark}
                    paddingHorizontalPixels={12}
                    paddingVerticalPixels={7}
                    onClick={() => onMonthChange(1)}
                    disabled={isNow}
                >
                    <ChevronRight size={18} color={colors.white} />
                </SalesButton>
            </div>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart width={730} height={250} data={items} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid vertical={false} />
                    <XAxis
                        dataKey="date"
                        type="number"
                        ticks={ticks}
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={tick => formatDate(tick)}
                    />
                    <YAxis
                        dataKey="totalPrice"
                        type="number"
                        orientation="right"
                        yAxisId={0}
                        domain={[0, 3]}
                        unit={` ${getCurrencySymbol(currentCurrency)}`}
                        tickMargin={2}
                        stroke="#8884d8"
                        tickFormatter={tick => tick.toLocaleString('ru-RU')}
                        width={100}
                    />
                    <Line type="monotone" dataKey="totalPrice" stroke="#8884d8" yAxisId={0} />
                    <YAxis
                        dataKey="totalQuantity"
                        type="number"
                        orientation="left"
                        yAxisId={1}
                        domain={[0, 4]}
                        unit=" шт"
                        tickMargin={2}
                        stroke="#82ca9d"
                    />
                    <Line type="monotone" dataKey="totalQuantity" stroke="#82ca9d" yAxisId={1} />
                    <Tooltip
                        wrapperStyle={{ outline: 'none' }}
                        content={<CustomTooltip currentCurrency={currentCurrency} />}
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export default SalesChart
