import { AxiosRequestConfig } from 'axios'
import { Dispatch } from 'redux'

import anchor from '../../../constants/anchor'
import { TCurrencyKeys } from '../../../types/common/commonTypes'
import { DocumentType } from '../../../types/document/documentTypes'
import { apiDocument } from '../../api/apiInstance'
import { RootState } from '../../store/rootReducer'
import { storeDispatchTypes } from '../../store/storeDispatchTypes'

const dowloadDocument =
    (id: string, documentType: DocumentType, fileType: string, currency: TCurrencyKeys) =>
    async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
        const { userData } = getState()
        const params = {
            userId: userData.smsAuth.userId,
            id,
            fileType,
            documentType,
            Authorization: `${anchor} ${localStorage.getItem('accessToken')}`,
            currency,
        }
        const config: AxiosRequestConfig = {
            headers: { Authorization: `${anchor} ${localStorage.getItem('accessToken')}` },
            responseType: 'blob',
        }
        dispatch({ type: storeDispatchTypes.setDocumentsLoader, value: { field: 'isDocumentDownloaded', value: true } })
        try {
            const response = await apiDocument.post('/download', params, config)

            const href = URL.createObjectURL(response.data)
            const a = document.createElement('a')
            a.href = href
            a.download = `${id}.${fileType}`
            a.click()
            URL.revokeObjectURL(href)
        } catch (e) {
            console.error('download document failed', e)
        } finally {
            dispatch({
                type: storeDispatchTypes.setDocumentsLoader,
                value: { field: 'isDocumentDownloaded', value: false },
            })
        }
    }

export default dowloadDocument
