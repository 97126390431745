import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { browserName } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'

import ListDocumentEmpty from '../../components/_atoms/ListDocumentEmpty/ListDocumentEmpty'
import ListNoItems from '../../components/_atoms/ListNoItems/ListNoItems'
import ListPagination from '../../components/_atoms/ListPagination/ListPagination'
import ListSkeleton from '../../components/_atoms/ListSkeleton/ListSkeleton'
import SalesButton from '../../components/_atoms/SalesButton/SalesButton'
import SalesText from '../../components/_atoms/SalesText/SalesText'
import ModalDataVendorDocument from '../../components/_molecules/ModalDataVendorDocument/ModalDataVendorDocument'
import CellActionsDocuments from '../../components/_orgamisms/CellActionsDocuments/CellActionsDocuments'
import { colors, fontSizes, fontWeights } from '../../constants/salesStyles'
import LoadDocumentList from '../../modules/redux/documentList/GetDocumentList'
import { useSelector } from '../../modules/store/customSelector'
import { storeDispatchTypes } from '../../modules/store/storeDispatchTypes'
import { adminRouteAlias, getAdminNavigationPath } from '../../router/adminRouteAlias'
import { Document, DocumentType, IncomingInvoice } from '../../types/document/documentTypes'
import { formatDate } from '../../utils/converters'
import { colWidth } from '../../utils/stylePatches'

import styles from './styles.module.scss'

const Documents = () => {
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false)

    const itemsCount = useSelector(state => state.documentList.documentList.dataCount || 0)
    const isLoading = useSelector(state => state.documentList.isLoading)
    const documents = useSelector(state => state.documentList.documentList.data)
    const dataCount = useSelector(state => state.documentList.documentList.dataCount)

    // Параметры поиска
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchParamsLoaded, setSearchParamsLoaded] = useState(0)
    const searchParamTypeOfDocuments = searchParams.get('type')
    const searchParamPage = searchParams.get('p')

    const page = useSelector(state => state.documentList.filters.page)
    const [perPage] = useState(50)
    const [typeOfDocuments, setTypeOfDocuments] = useState<DocumentType | undefined>(undefined)

    const navigate: NavigateFunction = useNavigate()

    const handleClose = () => {
        setIsModalOpen(false)
    }

    const goToPartListByDocument = (document: Document | IncomingInvoice) => {
        navigate(getAdminNavigationPath(adminRouteAlias.parts.location) + `?documentId=${document.id}`)
        dispatch({
            type: storeDispatchTypes.setDocumentFilterParts,
            value: {
                document: {
                    documentId: document.id,
                },
            },
        })
    }

    // Загрузка параметров из строки поиска
    useEffect(() => {
        if (searchParamPage !== `${page}` && (searchParamPage !== null || page !== 1)) {
            dispatch({
                type: storeDispatchTypes.setPageFilterDocuments,
                value: { page: Number(searchParamPage) > 1 ? Number(searchParamPage) : 1 },
            })
        }

        if (searchParamTypeOfDocuments) {
            if ((Object.values(DocumentType) as string[]).includes(searchParamTypeOfDocuments)) {
                setTypeOfDocuments(searchParamTypeOfDocuments as DocumentType)
            } else {
                console.error('Некорректный формат строки для type')
            }
        } else {
            setTypeOfDocuments(DocumentType.IncomingInvoice)
        }
        setSearchParamsLoaded(1)
    }, [searchParams])

    // Загрузка параметров в строку поиска
    useEffect(() => {
        if (!searchParamsLoaded) {
            return
        }

        // Текущие параметры
        const currentPage = page > 1 ? `${page}` : null
        const currentTypeOfDocuments = typeOfDocuments

        // Проверяем, изменились ли параметры строки поиска
        const pageChanged = searchParamPage !== currentPage
        const typeChanged = searchParamTypeOfDocuments !== currentTypeOfDocuments && !!currentTypeOfDocuments

        const searchParamsChanged = pageChanged || typeChanged

        if (searchParamsChanged) {
            setSearchParams({
                ...(currentPage ? { p: `${currentPage}` } : {}),
                ...(currentTypeOfDocuments ? { type: `${currentTypeOfDocuments}` } : {}),
            })
        }

        // load documents list data
        dispatch(LoadDocumentList(page, perPage, typeOfDocuments || DocumentType.IncomingInvoice))
    }, [searchParamsLoaded, page, perPage, typeOfDocuments])

    // Заменяем текущую запись в истории новой только если параметр type пустой
    useEffect(() => {
        if (!searchParamTypeOfDocuments) {
            navigate('/admin/documents?type=incomingInvoice', { replace: true })
        }
    }, [])

    return (
        <div className={styles.wrap}>
            <SalesText
                text={'Документы'}
                fontWeight={fontWeights.bold}
                fontSize={fontSizes.xxl}
                letterSpacing={-0.02}
            />
            <div className={styles.sortControls}>
                <SalesButton
                    borderRadius={10}
                    backgroundColor={typeOfDocuments === DocumentType.IncomingInvoice ? colors.grayDark : undefined}
                    paddingHorizontalPixels={20}
                    paddingVerticalPixels={7}
                    onClick={() => setTypeOfDocuments(DocumentType.IncomingInvoice)}
                >
                    <>
                        <SalesText
                            text={'Приходные накладные'}
                            fontSize={fontSizes.s}
                            color={typeOfDocuments === DocumentType.IncomingInvoice ? colors.white : undefined}
                        />
                    </>
                </SalesButton>
                <SalesButton
                    borderRadius={10}
                    backgroundColor={typeOfDocuments === DocumentType.Invoice ? colors.grayDark : undefined}
                    paddingHorizontalPixels={20}
                    paddingVerticalPixels={7}
                    onClick={() => setTypeOfDocuments(DocumentType.Invoice)}
                >
                    <>
                        <SalesText
                            text={'Расходные накладные'}
                            fontSize={fontSizes.s}
                            color={typeOfDocuments === DocumentType.Invoice ? colors.white : undefined}
                        />
                    </>
                </SalesButton>
                <SalesButton
                    borderRadius={10}
                    backgroundColor={typeOfDocuments === DocumentType.Receipt ? colors.grayDark : undefined}
                    paddingHorizontalPixels={20}
                    paddingVerticalPixels={7}
                    onClick={() => setTypeOfDocuments(DocumentType.Receipt)}
                >
                    <>
                        <SalesText
                            text={'Товарные чеки'}
                            fontSize={fontSizes.s}
                            color={typeOfDocuments === DocumentType.Receipt ? colors.white : undefined}
                        />
                    </>
                </SalesButton>
                <div className={styles.titleWrapRight}>
                    <SalesButton
                        borderRadius={10}
                        backgroundColor={colors.blueLight}
                        paddingHorizontalPixels={20}
                        paddingVerticalPixels={6}
                        onClick={() => setIsModalOpen(true)}
                    >
                        <SalesText
                            text={'Данные для документов'}
                            marginLeftPixels={10}
                            fontSize={fontSizes.s}
                            color={colors.white}
                        />
                    </SalesButton>
                </div>
            </div>
            {!isLoading && documents.length === 0 ? (
                <></>
            ) : (
                <ListPagination
                    page={page}
                    setPage={page =>
                        dispatch({
                            type: storeDispatchTypes.setPageFilterDocuments,
                            value: { page },
                        })
                    }
                    perPage={perPage}
                    itemsCount={itemsCount}
                    justifyContent={'flex-end'}
                />
            )}

            {isModalOpen && <ModalDataVendorDocument isOpen={isModalOpen} closeModal={() => handleClose()} />}

            <Table striped hover>
                <thead>
                    <tr>
                        <th scope="col" style={colWidth(100)}>
                            Номер
                        </th>
                        <th scope="col" style={colWidth(120)}>
                            Дата
                        </th>
                        {typeOfDocuments !== DocumentType.IncomingInvoice && (
                            <th scope="col" style={colWidth(160)}>
                                Покупатель
                            </th>
                        )}
                        <th scope="col" style={colWidth(100)}>
                            Кол-во
                        </th>
                        <th scope="col" style={colWidth(120)}>
                            Сумма
                        </th>
                        <th scope="col" style={colWidth(140)}>
                            Печать
                        </th>
                    </tr>
                </thead>
                {isLoading || !documents ? (
                    <ListSkeleton
                        rowCount={9}
                        columnCount={typeOfDocuments !== DocumentType.IncomingInvoice ? 6 : 5}
                        marginVerticalRem={0.21}
                    />
                ) : (
                    <tbody>
                        {documents.map((document, index) =>
                            'partsId' in document ? (
                                <tr key={index} className={styles.row}>
                                    <td scope="row" onClick={() => goToPartListByDocument(document)}>
                                        {document.title}
                                    </td>
                                    <td scope="row" onClick={() => goToPartListByDocument(document)}>
                                        {formatDate(document.createdAt)}
                                    </td>
                                    <td scope="row" onClick={() => goToPartListByDocument(document)}>
                                        {document.partsId.length}
                                    </td>
                                    <td scope="row" onClick={() => goToPartListByDocument(document)}>
                                        {/* {document.extraData?.amount} */}
                                    </td>
                                    <td scope="row">
                                        <CellActionsDocuments
                                            document={document}
                                            isSafari={browserName === 'Safari'}
                                            type={typeOfDocuments ? typeOfDocuments : DocumentType.IncomingInvoice}
                                        />
                                    </td>
                                </tr>
                            ) : (
                                <tr key={index} className={styles.row}>
                                    <td scope="row" onClick={() => goToPartListByDocument(document)}>
                                        {document.title}
                                    </td>
                                    <td scope="row" onClick={() => goToPartListByDocument(document)}>
                                        {formatDate(document.createdAt)}
                                    </td>
                                    <td scope="row" onClick={() => goToPartListByDocument(document)}>
                                        {
                                            // @ts-ignore
                                            'extra_data' in document && document.extra_data?.buyerName
                                        }
                                    </td>
                                    <td scope="row" onClick={() => goToPartListByDocument(document)}>
                                        {
                                            // @ts-ignore
                                            'extra_data' in document && document.extra_data?.parts.length
                                        }
                                    </td>
                                    <td scope="row" onClick={() => goToPartListByDocument(document)}>
                                        {
                                            // @ts-ignore
                                            'extra_data' in document && document.extra_data?.amount
                                        }
                                    </td>
                                    <td scope="row">
                                        <CellActionsDocuments
                                            document={document}
                                            isSafari={browserName === 'Safari'}
                                            type={typeOfDocuments ? typeOfDocuments : DocumentType.IncomingInvoice}
                                        />
                                    </td>
                                </tr>
                            ),
                        )}
                    </tbody>
                )}
            </Table>
            {!isLoading && documents.length === 0 ? (
                dataCount ? (
                    <ListNoItems slice={'documentList'} />
                ) : (
                    <ListDocumentEmpty slice={typeOfDocuments} />
                )
            ) : (
                <ListPagination
                    page={page}
                    setPage={page =>
                        dispatch({
                            type: storeDispatchTypes.setPageFilterDocuments,
                            value: { page },
                        })
                    }
                    perPage={perPage}
                    itemsCount={itemsCount}
                    increment={1}
                    justifyContent={'flex-end'}
                />
            )}
        </div>
    )
}

export default Documents
